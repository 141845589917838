<template>
    <div>
        <div v-if="doc">
            <div class="modal-row2 modal-height-max" id="myTabContent">
                <div>
                    <div class="modal-body">
                        <div class="row gutters">
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label for="taxpayer">{{ t('CLIENTS.CLIENT') }}: </label>
                                    <input type="text" id="taxpayer" name="taxpayer" :value="doc.client?.name"
                                           class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label for="taxpayer">{{ t('CLIENTS.DOCUMENTS.DOCUMENT_NAME') }}: </label>
                                    <input type="text" id="docName" name="taxpayer" v-model="doc.name"
                                           class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label for="created">{{ t('CLIENTS.DOCUMENTS.UPLOADED_AT') }}:</label>
                                    <input type="text" id="created" name="birthday"
                                           :value="dateFormat(doc.created_at)"
                                           class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label for="file_size">{{ t('CLIENTS.DOCUMENTS.SIZE') }}:</label>
                                    <input type="text" id="file_size" name="birthday"
                                           v-model="file_size"
                                           class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label>{{ t('CLIENTS.TYPE_ANEX') }}:</label>
                                    <Select2 :settings="{width: '100%'}" :options="optionsDriveType"
                                             placeholder="Tipo de Anexo"
                                             id="typeAnex" name="typeAnex"
                                             disabled
                                             v-model="doc.drive_files_type_id"
                                    ></Select2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="justify-content: space-between">
                <button data-cy="client_add_back" class="btn btn-primary mr-2" type="button" data-dismiss="modal">
                    {{ t('ACTIONS.CLOSE') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import Select2 from 'vue3-select2-component';
import DriveFilesType from '../../services/DriveFilesType';

export default {
    name: "viewDriveDocumentComponent",
    props: ['documentIndex'],
    data() {
        return {
            doc: {},
            optionsDriveType: [],
            file_size: null
        }
    },
    components: {
        Select2,
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    mounted() {
        this.listFilesType();
        this.doc = this.documentIndex;
        this.file_size = this.formatSize(this.documentIndex.file_size);
        this.$store.commit('changeLoading', false)
    },
    watch: {
        documentIndex: function () {
            this.doc = this.documentIndex;
            this.file_size = this.formatSize(this.doc.file_size);
            this.$store.commit('changeLoading', false)
        }
    },
    methods: {
        formatSize(fileSize) {
            if (!fileSize) return '0.0';
            if (fileSize < 1000) return `${fileSize} Bytes`;
            if (fileSize > 1000 && fileSize < 1000000) return `${(fileSize / 1000).toFixed()} KB`;
            if (fileSize >= 1000000 && fileSize < 1000000000) return `${(fileSize / 1000000).toFixed(2)} MB`;
            if (fileSize >= 1000000000) return `${(fileSize / 1000000000).toFixed(2)} GB`;
        },
        listFilesType() {
            DriveFilesType.list().then((resp) => {
                if (resp?.data?.length) {
                    resp.data.forEach((i) => {
                        this.optionsDriveType.push({id: i.id, text: i.text})
                    })
                }
            })
        },
        dateFormat(value) {
            if (!value) return;
            let dateAndTime = value.split('T');
            let date = dateAndTime[0].split('-');
            return `${date[2]}/${date[1]}/${date[0]}`;
        },
    }
}
</script>

<style scoped>

</style>